import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';

interface ILogo {
  letter: string,
  color: string,
}

const logo: ILogo[] = [
  { letter: 'U', color: '#E30613' },
  { letter: 'T', color: '#009640' },
  { letter: 'S', color: '#213A8F' },
];

type LogoProps = BoxProps;
const Logo: React.FC<LogoProps> = ({ sx, ...props }) => {
  return (
    <Box sx={{ display: 'flex', gap: '5px', ...sx }} {...props}>
      {logo.map((item: ILogo) => (
        <Box
          key={item.letter}
          sx={{
            minWidth: 30,
            height: 30,
            bgcolor: item.color,
          }}>
          <Typography
            sx={{
              fontSize: 30,
              lineHeight: '30px',
              textAlign: 'center',
              fontWeight: 500,
              color: '#fff',
            }}
          >
            {item.letter}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Logo;