import React, { memo, useCallback, useState } from 'react';
import { Avatar, IconButton } from '@mui/material';
import ProfileMenu from '../dumps/ProfileMenu';
import { useAuth } from '../providers/AuthProvider';

const ProfileButton = () => {
  const { user } = useAuth();
  const [menuState, setMenuState] = useState<{isOpen: boolean, anchorElement: null | HTMLElement}>({
    isOpen: false,
    anchorElement: null,
  });

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuState({ isOpen: true, anchorElement: event.currentTarget });
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuState({ isOpen: false, anchorElement: null });
  }, []);

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <Avatar alt={user?.photo} src={user?.photo} />
      </IconButton>
      {
        user && (
          <ProfileMenu
            user={user}
            anchorEl={menuState.anchorElement}
            open={menuState.isOpen}
            onClose={handleMenuClose}
          />
        )
      }
    </>
  );
};

export default memo(ProfileButton);