import React from 'react'
//import { useUnit } from 'effector-react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
//import useMediaQuery from "@mui/material/useMediaQuery";
import theme from '../../theme/normal';
//import { $systemStore } from '../models/system';

const CustomThemeContext = React.createContext(
  {
    currentTheme: 'normal',
    setTheme: null,
  },
)

type ThemeProviderProps = {
  children: React.ReactNode,
}


const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {

  // Get OS-level preference for dark mode
  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  //const { theme: themeName } = useUnit($systemStore);

  // Retrieve the theme object by theme name
  // const theme = getTheme(themeName || (prefersDarkMode ? 'dark' : 'normal'))

  return (
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider>
  );
}

export default ThemeProvider
