export type DealType = 'directSale' | 'tender';

export enum DEAL_TYPES {
  directSale = 'Direct Sale',
  tender = 'Tender',
}

export type Deal = {
  id?: string;
  partnerId?: string;
  companyName: string;
  country: string;
  email: string;
  phone: string;
  dealType: DealType;
  leadComingDate?: Date;
  createdAt?: Date; //hidden
  updatedAt?: Date; //hidden
}

export type SimilarDeal = Deal &{
  color: string;
  matchRating: 'high' | 'medium' | 'low';
}
