import cfg from '../config';
import { Deal } from '../const/deal';

const url = `${cfg.apiUrl}/api/v1/deals`;

export const register = async (data: Deal) => {
  const res = await fetch(`${url}/register`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const update = async (data: Deal): Promise<Deal> => {
  const res = await fetch(`${url}/${data.id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

export const remove = async (id: string): Promise<Deal> => {
  const res = await fetch(`${url}/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();  
};