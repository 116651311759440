import { ButtonProps, IconButton, IconButtonProps } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useCallback, useState } from "react";
import DealDialog from "../dialogs/DealDialog";
import { Deal } from "../../const/deal";

type Props = {
  init: Deal
  initTenderDate?: string
  disabled?: boolean
  onSubmit: (data: Deal, tenderDate?: string) => void
}

function EditDealButton({ init, initTenderDate, disabled, onSubmit }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback((data: Deal, tenderDate?: string) => {
    setOpen(false);
    onSubmit(data, tenderDate);
  }, [onSubmit]);

  return (
    <>
      <IconButton aria-label="edit" disabled={disabled} onClick={handleOpen} >
        <EditIcon />
      </IconButton>
      {open && 
        <DealDialog
          open={open}
          init={init}
          initTenderDate={initTenderDate}
          onClose={handleClose}
          onSave={handleSubmit}
        />
      }
    </>
  );
}

export default EditDealButton;
