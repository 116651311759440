import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import DealDialog from "../dialogs/DealDialog";
import { Deal } from "../../const/deal";

type Props = {
  onSubmit: (data: Deal, tenderDate?: string) => void
}

function AddDealButton({ onSubmit }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback((data: Deal, tenderDate?: string) => {
    setOpen(false);
    onSubmit(data, tenderDate);
  }, [onSubmit]);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleOpen}>
        Add Deal
      </Button>
      {open && 
        <DealDialog open={open} onClose={handleClose} onSave={handleSubmit} />
      }
    </>
  );
}

export default AddDealButton;
