import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { SimilarDeal, DEAL_TYPES, Deal } from "../../const/deal";
import { DealGuard } from "../../const/deal-guard";
import { guardStatusToColor } from "../../lib/guardStatusToColor";
import useSWR from "swr";
import { useMemo } from "react";
import { DateTime } from "luxon";
import cfg from "../../config";

const tableHeaders = ['Dealer', 'Country', 'Company', 'Email', 'Phone', 'Created at', 'Lead coming date', 'Deal type', 'Guard status', 'Manager', 'Similar'];

type Props = {
  deal: Deal
};

function SimilarDeals({ deal }: Props) {
  const { data: similarDeals = [] } = useSWR(`/api/v1/deals/${deal.id}/similar`);
  const { data: dealGuards = [] } = useSWR(`/api/v1/deal-guards`);
  const { data: dealers = [] } = useSWR(`${cfg.dealerMicro}/api/v1/dealers`);

  const similarDealsWithoutCurrent = useMemo(() => {
    return similarDeals.filter((sd: SimilarDeal) => sd.id !== deal.id) || [];
  }, [deal.id, similarDeals]);

  return (
    <Box my={4}>
      <Typography variant='h5' align='center' mb={2}>Similar Deals</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell key={header}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!similarDealsWithoutCurrent.length && 
              <TableRow><TableCell colSpan={tableHeaders.length} align='center'>No similar deals</TableCell></TableRow>
            }
            {similarDealsWithoutCurrent.map((sd: SimilarDeal) => (
              <TableRow
                key={sd.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{dealers.find((d: any) => d.id === sd.partnerId)?.name}</TableCell>
                <TableCell component="th" scope="row">{sd.country}</TableCell>
                <TableCell>{sd.companyName}</TableCell>
                <TableCell>{sd.email}</TableCell>
                <TableCell>{sd.phone}</TableCell>
                <TableCell>{sd.createdAt ? DateTime.fromISO(sd.createdAt.toString()).toLocaleString(DateTime.DATETIME_MED) : '-'}</TableCell>
                <TableCell>{sd.leadComingDate ? DateTime.fromISO(sd.leadComingDate.toString()).toLocaleString(DateTime.DATE_MED) : '-'}</TableCell>
                <TableCell>{DEAL_TYPES[sd.dealType]}</TableCell>
                <TableCell>
                  <Typography
                    variant='body2' sx={{ color: `${guardStatusToColor(dealGuards.find((dg: DealGuard) => dg.dealId === sd.id)?.status)}.main` }}
                  >
                    {dealGuards.find((dg: DealGuard) => dg.dealId === sd.id)?.status}
                  </Typography>
                </TableCell>
                <TableCell>-</TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant='body2' sx={{ color: `${sd.color}.main` }}>{sd.matchRating}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SimilarDeals;