import React, { useCallback } from 'react';
import Page from '../components/layouts/Page';
import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AddDealButton from '../components/containers/AddDealButton';
import EditDealButton from '../components/containers/EditDealButton';
import RemoveDealButton from '../components/containers/RemoveDealButton';
import { DealGuard } from '../const/deal-guard';
import { Deal, DEAL_TYPES } from '../const/deal';
import * as dealApi from '../api/deal';
import * as dialGuardsApi from '../api/deal-guards'
import useSWR from 'swr';
import { guardStatusToColor } from '../lib/guardStatusToColor';
import cfg from '../config';
import { Dealer } from './manage-deals';

const tableHeaders = ['Dealer', 'Company', 'Country', 'Email', 'Phone', 'Deal type', 'Guard status', 'Action'];

const MyDeals: React.FC = () => {
  const { data: deals = [], mutate: mutateDeals } = useSWR('/api/v1/deals');
  const { data: dealGuards = [], mutate: mutateGuards } = useSWR('/api/v1/deal-guards');
  const { data: dealers = [] } = useSWR(`${cfg.dealerMicro}/api/v1/dealers`);

  const handleAddDeal = useCallback(async (data: Deal, protectionExpiryDate?: string) => {
    try {
      const newDeal = await dealApi.register(data);
      mutateDeals((d: Deal[]) => [...d, newDeal], false);
      const newGuard = await dialGuardsApi.create({ dealId: newDeal.id, status: 'pending', protectionExpiryDate });
      mutateGuards((d: DealGuard[]) => [...d, newGuard], false);
    } catch (error) {
      console.log(error);
    }
  }, [])

  const handleEditDeal = useCallback(async (data: Deal) => {
    try {
      const res = await dealApi.update(data);
      mutateDeals((d: Deal[]) => d.map((d: Deal) => d.id === res.id ? data : d), false);
    } catch (error) {
      console.log(error);
    }
  }, [])

  const handleRemoveDeal = useCallback((data: Deal) => {
    // TODO add check for deal guard and ask for confirmation
    try {
      if (data.id) {
        dealApi.remove(data.id);
      }
      mutateDeals((d: Deal[]) => d.filter((d: Deal) => d.id !== data.id), false);
    } catch (error) {
      console.log(error);
    }
  }, [])

  return (
    <Page title='My Deals'>
      <Stack direction="row" justifyContent="flex-end" my={2}>
        <AddDealButton onSubmit={handleAddDeal} />
      </Stack>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!deals.length && <TableRow><TableCell colSpan={8} align="center" sx={{ border: 0 }}>No deals</TableCell></TableRow>}
              {deals.map((deal: Deal) => (
                <TableRow
                  key={deal.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{dealers.find((d: Dealer) => d.id === deal.partnerId)?.name || ''}</TableCell>
                  <TableCell>{deal.companyName}</TableCell>
                  <TableCell component="th" scope="row">{deal.country}</TableCell>
                  <TableCell>{deal.email}</TableCell>
                  <TableCell>{deal.phone}</TableCell>
                  <TableCell>{DEAL_TYPES[deal.dealType]}</TableCell>
                  <TableCell>
                    <Typography variant='body2' sx={{ color: `${guardStatusToColor(dealGuards.find((dg: DealGuard) => dg.dealId === deal.id)?.status)}.main` }}>
                      {dealGuards.find((dg: DealGuard) => dg.dealId === deal.id)?.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <EditDealButton
                      init={deal}
                      initTenderDate={dealGuards.find((g: DealGuard) => g.dealId === deal.id)?.protectionExpiryDate}
                      disabled={dealGuards.find((g: DealGuard) => g.dealId === deal.id)?.status !== 'pending'}
                      onSubmit={handleEditDeal}
                    />
                    <RemoveDealButton
                      data={deal}
                      disabled={dealGuards.find((g: DealGuard) => g.dealId === deal.id)?.status !== 'pending'}
                      onRemove={handleRemoveDeal}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Page>
  );
};

export default MyDeals;