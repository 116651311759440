import { IconButton } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useCallback, useState } from "react";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import { Deal } from "../../const/deal";

type Props = {
  data: Deal
  disabled?: boolean
  onRemove: (data: Deal) => void
  confirmTitle?: string
  confirmContent?: string
}

function RemoveDealButton({ data, disabled, onRemove, confirmTitle, confirmContent }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleRemove = useCallback(async () => {
    console.log(data);
    setOpen(false);
    onRemove(data);
  }, [data, onRemove]);

  return (
    <>
      <IconButton aria-label="delete" sx={{ ml: '1' }} disabled={disabled} onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      {open && 
        <ConfirmDialog
          open={open}
          title={confirmTitle || 'Remove Deal'}
          content={confirmContent || 'Are you sure you want to remove this deal?'}
          rejectButtonText={'Cancel'}
          confirmButtonText={'Remove'}
          onReject={handleClose}
          onConfirm={handleRemove}
      />
      }
    </>
  );
}

export default RemoveDealButton;
