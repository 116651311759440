import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from './components/providers/ThemeProvider';
import SWRProvider from './components/providers/SWRProvider';
import { AuthProvider } from './components/providers/AuthProvider';
import StartPage from './pages';
import MyDealsPage from './pages/my-deals';
import ManageDealsPage from './pages/manage-deals';
import DealGuardPage from './pages/deal-guard';

function App() {
  return (
    <Router>
      <SWRProvider>
        <ThemeProvider>
          <AuthProvider>
              <CssBaseline />
              <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/my-deals" element={<MyDealsPage />} />
                <Route path="/manage-deals" element={<ManageDealsPage />} />
                <Route path="/deal-guard/:id" element={<DealGuardPage />} />
              </Routes>
          </AuthProvider>
        </ThemeProvider>
      </SWRProvider>
    </Router>
  );
}

export default App;
