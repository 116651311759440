import { useForm } from "react-hook-form"
import { Box, Button, Dialog, DialogContent, DialogTitle, MenuItem, Stack, TextField } from "@mui/material"
import { FormInputText } from "../form/FormInputText"
import { Deal } from "../../const/deal"
import { useEffect, useState } from "react"
import useSWR from "swr"
import cfg from "../../config"
import { Dealer } from "../../pages/manage-deals"
import { head } from "lodash"

const defaultInit: Deal = {
  companyName: '',
  country: '',
  email: '',
  phone: '',
  dealType: 'directSale',
}

type Props = {
  open: boolean
  init?: Deal
  initTenderDate?: string
  onClose: () => void
  onSave: (data: Deal, tenderDate?: string) => void
}

function DealDialog({ open, init = defaultInit, initTenderDate, onClose, onSave }: Props) {
  const { data: myDealers = [] } = useSWR(`${cfg.dealerMicro}/api/v1/dealers/me`);
  const { control, handleSubmit, reset, watch } = useForm<Deal>({
    defaultValues: init,
    mode: 'onSubmit',
  })
  const [isTenderDate, setIsTenderDate] = useState(init.dealType === 'tender')
  const [tenderDate, setTenderDate] = useState(initTenderDate?.slice(0, 10))

  useEffect(() => {
    const { unsubscribe } = watch((v) => {
      setIsTenderDate(v.dealType === 'tender')
    })
    return () => unsubscribe()
  }, [watch])

  const handleSave = (data: Deal) => {
    onSave({ ...data, partnerId: myDealers[0].id }, tenderDate);
    reset(defaultInit);
    onClose();
  }

  return open && (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{`${init.id ? 'Update' : 'Add'} Deal`}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleSave)}>
          <Stack spacing={2} py={2}>
            {/* {myDealers.length > 1 && (
              <FormInputText
                name="partnerId"
                select
                control={control}
                label="Partner"
                errorMessage={'Please select partner'}
              >
                {myDealers.map((d: Dealer) => (
                  <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
                ))}
              </FormInputText>
            )} */}
            <FormInputText
              name="companyName"
              control={control}
              label="Company name"
              required
              errorMessage={'Please enter company name'}
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="country"
              control={control}
              label="Country"
              required
              errorMessage={'Please enter country'}
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="email"
              control={control}
              label="Email"
              required
              errorMessage={'Please enter email'}
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="phone"
              control={control}
              label="Phone"
              errorMessage={'Please enter phone'}
              rules={{ maxLength: 250 }}
            />
            <FormInputText
              name="dealType"
              select
              control={control}
              label="Deal Type"
              errorMessage={'Please enter deal type'}
            >
              <MenuItem value="directSale">Direct Sale</MenuItem>
              <MenuItem value="tender">Tender</MenuItem>
            </FormInputText>
            {isTenderDate && (
              <TextField
                name="tenderDate"
                type="date"
                label="Tender Date"
                value={tenderDate}
                InputLabelProps={{ shrink: true }}
                size="small"
                onChange={(e) => {setTenderDate(e.target.value)}}
              />
            )}
            <FormInputText
              name="leadComingDate"
              type="date"
              control={control}
              label="Lead Coming Date"
              inputLabelProps={{ shrink: true }}
            />
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
            <Button variant="contained" color="secondary" onClick={onClose} sx={{ mr: 2 }}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">Save</Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default DealDialog;
