import React, { useCallback, useMemo, useState } from 'react';
import Page from '../components/layouts/Page';
import { Box, Button, Divider, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import { guardStatusToColor } from '../lib/guardStatusToColor';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { DealGuard } from '../const/deal-guard';
import * as api from '../api/deal-guards';
import { DateTime } from 'luxon';
import DealGuardSuccessDialog from '../components/dialogs/DealGuardSuccessDialog';
import DealGuardRejectDialog from '../components/dialogs/DealGuardRejectDialog';
import SimilarDeals from '../components/containers/SimilarDeals';
import { Deal, DEAL_TYPES } from '../const/deal';


const DealGuardPage: React.FC = () => {
  const { id: dealId } = useParams();
  const { data: deal = {}, isLoading } = useSWR(`/api/v1/deals/${dealId}`);
  const { data: dealGuards = [], mutate } = useSWR<DealGuard[]>(`/api/v1/deal-guards?dealId=${dealId}`);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const guardStatus = useMemo(() => {
    return dealGuards.find(g => g.dealId === dealId)?.status;
  }, [dealGuards, dealId]);

  const handleApproveDealGuard = useCallback(async (protectionExpiryDate: string) => {
    try {
      if (dealGuards[0]?.id) {
        await api.update({ ...dealGuards[0], status: 'approved', protectionExpiryDate });      
        mutate();
        setOpenSuccess(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dealGuards, mutate]);

  const handleRejectDealGuard = useCallback(async (reason: string) => {
    try {
      if (dealGuards[0]?.id) {
        await api.update({ ...dealGuards[0], status: 'rejected', reason });      
        mutate();
        setOpenReject(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dealGuards, mutate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Page title={`Guard for deal ${deal.companyName}`}>
      <Box my={4}>
        <Paper sx={{ pt: 1, px: 2, pb: 2 }}>
          <Typography variant='h6'>Deal</Typography>
          <Divider sx={{ mt: 1, mb: 3 }}/>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField label='Company name' value={deal.companyName} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
            </Grid>
            <Grid item xs={4}>
              <TextField label='Country' value={deal.country} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
            </Grid>
            <Grid item xs={3.5}>
              <TextField label='Email' value={deal.email} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
            </Grid>
            <Grid item xs={2}>
              <TextField label='Phone' value={deal.phone ? deal.phone : '-'} InputProps={{ readOnly: true }} fullWidth size='small' disabled/>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                label='Created at'
                value={DateTime.fromISO(deal.createdAt || '').toLocaleString(DateTime.DATETIME_MED)}
                InputProps={{ readOnly: true }}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                label='Lead coming date'
                value={deal.leadComingDate ? DateTime.fromISO(deal.leadComingDate).toLocaleString(DateTime.DATE_MED) : '-'}
                InputProps={{ readOnly: true }}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                label='Deal type'
                value={DEAL_TYPES[deal.dealType as keyof typeof DEAL_TYPES]}
                InputProps={{ readOnly: true }}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ pt: 1, px: 2, pb: 2, mt: 2 }}>
          <Typography variant='h6' mb={2}>Deal Guard</Typography>
          {dealGuards[0]?.id &&
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  label='Protection expiry date'
                  value={dealGuards[0].protectionExpiryDate ? DateTime.fromISO(dealGuards[0].protectionExpiryDate).toLocaleString(DateTime.DATE_MED) : '-'}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label='Guard status'
                  value={dealGuards[0].status.toUpperCase()}
                  color={guardStatusToColor(dealGuards[0].status)}
                  focused
                  InputProps={{ readOnly: true }}
                  fullWidth
                  size='small'
                  sx={{ input: { cursor: 'default' } }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label='Updated at'
                  value={DateTime.fromISO(dealGuards[0].updatedAt || '')?.toLocaleString(DateTime.DATETIME_MED)}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
              {dealGuards[0].status === 'rejected' &&
                <Grid item xs={12}>
                  <TextField
                    label='Rejection reason'
                    value={dealGuards[0].reason}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    size='small'
                    disabled
                  />
                </Grid>
              }
            </Grid>
          }
          <Stack direction='row' spacing={2} mt={4}>
            <Button
              variant={guardStatus === 'approved' ? 'contained' : 'outlined'}
              color='success'
              onClick={() => setOpenSuccess(true)}
            >
              {guardStatus === 'approved' ? 'Approved' : 'Approve'}
            </Button>
            <Button
              variant={guardStatus === 'rejected' ? 'contained' : 'outlined'}
              color='error'
              onClick={() => setOpenReject(true)}
            >
              {guardStatus === 'rejected' ? 'Rejected' : 'Reject'}
            </Button>
          </Stack>
        </Paper>
        <SimilarDeals deal={deal} />
        {openSuccess &&
          <DealGuardSuccessDialog
            open={openSuccess}
            initExpireDate={dealGuards[0].protectionExpiryDate || ''}
            onCancel={() => setOpenSuccess(false)}
            onConfirm={handleApproveDealGuard}
          />
        }
        {openReject &&
          <DealGuardRejectDialog
            open={openReject}
            initReason={dealGuards[0].reason}
            onCancel={() => setOpenReject(false)}
            onConfirm={handleRejectDealGuard}
          />
        }      
      </Box>
    </Page>
  );
};

export default DealGuardPage;