import { Box, Button, Dialog, DialogContent, DialogProps, DialogTitle, Typography } from "@mui/material"
import { Color } from "../../const/commonTypes"

type Props = DialogProps & {
  open: boolean
  title?: string
  content?: string
  rejectButtonText?: string
  rejectButtonColor?: Color
  confirmButtonText?: string
  confirmButtonColor?: Color
  onReject: () => void
  onConfirm: () => void
}

function ConfirmDialog(props: Props) {
  const {
    open,
    title = 'Confirm',
    content = 'Are you sure?',
    rejectButtonText = 'Cancel',
    rejectButtonColor = 'primary',
    confirmButtonText = 'Confirm',
    confirmButtonColor = 'error',
    onReject,
    onConfirm,
    ...rest
  } = props;

  return open && (
    <Dialog open={open} onClose={onReject} maxWidth="sm" fullWidth {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} mt={2}>
          <Button variant="contained" color={rejectButtonColor} sx={{ mr: 2 }} onClick={onReject}>
            {rejectButtonText}
          </Button>
          <Button variant="contained" color={confirmButtonColor} onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDialog;
