import React, { useCallback, useEffect, useState } from 'react';
import Page from '../components/layouts/Page';
import { Autocomplete, Box, Button, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { guardStatusToColor } from '../lib/guardStatusToColor';
import { useNavigate } from 'react-router-dom';
import { Deal, DEAL_TYPES } from '../const/deal';
import { DealGuard, DealGuardStatus } from '../const/deal-guard';
import useSWR from 'swr';
import { DateTime } from 'luxon';
import cfg from '../config';

export type Dealer = {
  id: string;
  name: string;
}

const tableHeaders = ['Dealer', 'Country', 'Company', 'Email', 'Phone', 'Created at', 'Lead coming date', 'Deal type', 'Guard status'];

const ManageDeals: React.FC = () => {
  const navigate = useNavigate();
  const { data: deals = [] } = useSWR<Deal[]>('/api/v1/deals');
  const { data: dealGuards = [] } = useSWR<DealGuard[]>('/api/v1/deal-guards');
  const { data: dealers = [] } = useSWR<Dealer[]>(`${cfg.dealerMicro}/api/v1/dealers`);

  const [filteredDeals, setFilteredDeals] = useState<Deal[]>(deals);

  useEffect(() => {
    setFilteredDeals(deals);
  }, [deals]);

  const handleEditDealGuard = useCallback((data: Deal) => {
    navigate(`/deal-guard/${data.id}`);
  }, [])

  const handleSelectDealer = useCallback((_: any, data: Dealer[] = []) => {
    const selectedDealers = data.map((d: Dealer) => d.id);
    if (selectedDealers.length === 0) {
      setFilteredDeals(deals);
    } else {
      setFilteredDeals(deals.filter((deal: Deal) => selectedDealers.includes(deal.partnerId || '')));      
    }
  }, [deals])

  return (
    <Page title='Manage Deals'>
      <Box display='flex' justifyContent='flex-end'>
        <Autocomplete
          id="dealer-filter"
          multiple
          options={dealers}
          getOptionLabel={(option: Dealer) => option.name}
          isOptionEqualToValue={(option: Dealer, value: Dealer) => option.id === value.id}
          sx={{ minWidth: 300 }}
          onChange={handleSelectDealer}
          renderInput={(params) => <TextField {...params} label="Dealer filter"/>}
        />
      </Box>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDeals.map((deal: Deal) => (
                <TableRow
                  key={deal.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  hover
                  onClick={() => handleEditDealGuard(deal)}
                >
                  <TableCell component="th" scope="row">{dealers.find(d => d.id === deal.partnerId)?.name}</TableCell>
                  <TableCell component="th" scope="row">{deal.country}</TableCell>
                  <TableCell>{deal.companyName}</TableCell>
                  <TableCell>{deal.email}</TableCell>
                  <TableCell>{deal.phone}</TableCell>
                  <TableCell>
                    {deal.createdAt ? DateTime.fromISO(deal.createdAt.toString()).toLocaleString(DateTime.DATETIME_MED) : '-'}
                  </TableCell>
                  <TableCell>
                    {deal.leadComingDate ? DateTime.fromISO(deal.leadComingDate.toString()).toLocaleString(DateTime.DATE_MED) : '-'}
                  </TableCell>
                  <TableCell>{DEAL_TYPES[deal.dealType]}</TableCell>
                  <TableCell>
                    <Typography variant='body2' sx={{ color: `${guardStatusToColor(dealGuards.find((dg: DealGuard) => dg.dealId === deal.id)?.status)}.main` }}>
                      {dealGuards.find((dg: DealGuard) => dg.dealId === deal.id)?.status}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Page>
  );
};

export default ManageDeals;